<template>
  <div>
    <div class="px-2">

      <div class="row">
        <div class="d-flex w-100 align-items-end mb-1">

          <div class="w-100 mr-1">
            <input v-model="currentSetting.value" type="text" class="form-control" id="callRequestReferencePrefix" placeholder="Call Request Reference Prefix">
          </div>

          <div>
            <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingCallRequestReferencePrefix",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['callRequestReferencePrefix'],
  data() {
    return {
      currentSetting: this.callRequestReferencePrefix,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Call request reference prefix updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>