<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="call-request-setting-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Call Request/List</h4>
          </div>

          <div class="accordion" id="accordionContact">

            <div class="card">
              <div class="card-header" id="settingCallRequestReferencePrefixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingCallRequestReferencePrefixCollapse" aria-expanded="true" aria-controls="collapseCallRequestReferencePrefix">
                  <span class="text-capitalize call-request-title">Call Request Reference Prefix</span>
                </button>
              </div>
              <div id="settingCallRequestReferencePrefixCollapse" class="collapse" aria-labelledby="settingCallRequestReferencePrefixHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingCallRequestReferencePrefix :call-request-reference-prefix="this.callRequestReferencePrefix" />
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="settingCallRequestReferenceSuffixHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingCallRequestReferenceSuffixCollapse" aria-expanded="true" aria-controls="collapseCallRequestReferenceSuffix">
                  <span class="text-capitalize call-request-title">Call Request Reference Suffix</span>
                </button>
              </div>
              <div id="settingCallRequestReferenceSuffixCollapse" class="collapse" aria-labelledby="settingCallRequestReferenceSuffixHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingCallRequestReferenceSuffix :call-request-reference-suffix="this.callRequestReferenceSuffix" />
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import SettingCallRequestReferencePrefix from "@/views/backEnd/settings/callRequest/includes/SettingCallRequestReferencePrefix";
import SettingCallRequestReferenceSuffix from "@/views/backEnd/settings/callRequest/includes/SettingCallRequestReferenceSuffix";

export default {
  name: "SettingContactList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingCallRequestReferencePrefix,
    SettingCallRequestReferenceSuffix,
  },
  data() {
    return {
      getSettingsParams: {
        type: ["call_request"],
        key: [
          'call_request_reference_prefix',
          'call_request_reference_suffix',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      callRequestReferencePrefix: 'appSettings/settingCallRequestReferencePrefix',
      callRequestReferenceSuffix: 'appSettings/settingCallRequestReferenceSuffix',
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.call-request-wrapper .accordion .card{
  overflow: visible !important;
}
.call-request-title{
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>